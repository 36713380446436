// Define your breakpoints
$breakpoints: (
  "xs": 480px,
  "sm": 630px,
  "md": 1024px,
  "lg": 1200px,
  "xl": 1400px
);

// Mixin to handle media queries
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @media (max-width: $value) {
      @content;
    }
  } @else {
    @warn "Breakpoint #{$breakpoint} not found in \$breakpoints map.";
  }
}

