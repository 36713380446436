@import "mixins";
@import "variable";
@import "navbar";
@import "landingPage";
@import "contact";
@import "about";
@import "carsList";


*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
.App {
  font-family: Arial, Helvetica, sans-serif;

}
