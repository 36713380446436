.aboutContainer {
  // background-color: black;
  .about-us {
    padding: 20px;
    max-width: 900px;
    margin: auto;
    text-align: center;
    opacity: 20;

    .imgSection {
      // }
      .brabus {
        background: url("../assets/Hero_with_Brabus_wh_bg.png") center center
          no-repeat;
        background-size: cover; /* This will ensure the image covers the entire container */
        width: 100%;
        height: 25vh; /* Full height for demonstration */
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h1 {
        font-size: 2.5rem;
        color: #333;
      }
    }

    h2 {
      font-size: 2rem;
      margin-top: 20px;
      color: #555;
    }

    p {
      font-size: 1rem;
      color: #666;
      line-height: 1.6;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        font-size: 1rem;
        color: #666;
        line-height: 1.6;
        margin-bottom: 10px;
        text-align: left;
        strong {
          color: #333;
        }
      }
    }
  }
}

// }
@include respond-to("sm") {
  .about-us {
    padding: 10px;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    p,
    ul li {
      font-size: 0.9rem;
    }
  }
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .brabus {
    background-size: contain; /* Adjust to contain for smaller screens */
    height: auto; /* Adjust height as needed */
  }
}
