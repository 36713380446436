.landingPage {
  .hero {
    height: 75vh;
    background: url("../assets/Hero_with_Brabus.png") no-repeat center
      center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    position: relative;
  }
}

/* Responsive adjustments */
@media (max-width: 1640px) {
  .landingPage .hero {
    height: 70vh; /* Adjust height as needed */
    background-position: center center;
  }
}

@media (max-width: 1024px) {
  .landingPage .hero {
    margin-top: 80px;
    height: 50vh; /* Adjust height as needed */
    background-position: center center;
  }
}
// @media (min-width: 800px) {
//   .landingPage .hero {
//     // margin-top: 150px;
//     // height: 40vh; /* Adjust height as needed */
//     background-position: center center;
//   }
// }

@media (max-width: 768px) {
  .landingPage .hero {
    margin-top: 80px;
    height: 40vh; /* Adjust height as needed */
    background-position: center center;
  }
}

@media (max-width: 480px) {
  .landingPage .hero {
    margin-top: 140px;
    height: 25vh; /* Adjust height as needed */
    background-position: center center contain;
    width: 100vw0vw;
  }
}
