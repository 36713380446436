@import "../styles/variable";

.DatePickContainer {
  // width: 50vw;
  display: flex;
  justify-content: center;
  padding: 10px;
  // margin: 0% auto;
  margin-top: 5px;
  flex-wrap: wrap;
  .DatePick {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    // width: ;
    .PickerDate {
      padding: 10px;
      width: 150px;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      border-right: none;
    }
    .PickerTime {
      padding: 10px;
      width: 150px;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
}
.showCarsBtn {
  text-align: center;
  // z-index: -1;
  margin: 5px;
  button {
    // width: fit-content;
    display: inline-block;
    position: relative;
    z-index: 1;
    overflow: if($debug, unset, hidden);

    text-decoration: none;

    font-family: sans-serif;
    font-weight: 600;
    font-size: 2em;

    padding: 0em 1em;

    color: $bgColor;

    border: 0.1em solid $bgColor;
    border-radius: calc(0.75em + 0.5em + 0.15em);
    box-shadow: 10px 10px 10px black;

    transition: 2s;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: -1.5em;

      z-index: -1;

      width: 200%;
      aspect-ratio: 1;

      border: if($debug, inherit, none);
      border-radius: 40%;

      background-color: rgba($bgColor, 0.25);

      transition: 2s;
    }

    &:before {
      left: -80%;
      transform: translate3d(0, 5em, 0) rotate(-340deg);
    }

    &:after {
      right: -80%;
      transform: translate3d(0, 5em, 0) rotate(390deg);
    }

    &:hover,
    &:focus {
      color: $textHoverColor;

      &:before,
      &:after {
        transform: none;
        background-color: rgba($bgColor, 0.75);
      }
    }
  }
}

.cntr {
  margin-top: 40px;
  top: calc(50% - 10px);
  left: 0;
  width: 100%;
  text-align: center;

  .radio {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    font-size: 14px;
    margin: 15px;

    .label {
      position: relative;
      display: block;
      float: left;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      border: 2px solid $gray;
      border-radius: 100%;
      -webkit-tap-highlight-color: transparent;

      &:after {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: $primary;
        transform: scale(0);
        transition: all 0.2s ease;
        opacity: 0.08;
        pointer-events: none;
      }
    }

    &:hover .label:after {
      transform: scale(3.6);
    }

    input[type="radio"]:checked + .label {
      border-color: $primary;

      &:after {
        transform: scale(1);
        transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        opacity: 1;
      }
    }
  }
}

.hidden {
  display: none;
}

.credit {
  position: fixed;
  right: 20px;
  bottom: 20px;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0.6;

  img {
    width: 72px;
  }

  &:hover {
    transform: scale(0.95);
  }
}

.CarList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  .click {
    border: 5px solid rgb(0, 0, 0);
    border-radius: 20px;
  }
  .carCardContainer {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 450px;
    width: 500px;
    text-align: center;
    margin: 20px;

    word-wrap: break-word;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    .detailsDiv {
      padding: 10px;

      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      p {
        border-bottom: 1px solid rgba(0, 0, 0, 0.223);
        border-top: 1px solid rgba(0, 0, 0, 0.223);

      }
    }

    .ButtonsCardDiv {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .whatsAppDiv {
        .button1 {
          display: inline-block;
          transition: all 0.2s ease-in;
          position: relative;
          overflow: hidden;
          z-index: 1;
          color: #090909;
          padding: 0.7em 2.1em 0.7em 1em;
          font-size: 18px;
          border-radius: 0.5em;
          background: #e8e8e8;
          border: 1px solid #e8e8e8;
          box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
          text-decoration: none;

          svg {
            height: 30px;
            position: absolute;
            padding: 0px 5px;
            margin-top: -4px;
          }

          &:active {
            color: #666;
            box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
          }

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%) scaleY(1) scaleX(1.25);
            top: 100%;
            width: 140%;
            height: 180%;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 50%;
            display: block;
            transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
            z-index: -1;
          }

          &:after {
            content: "";
            position: absolute;
            left: 55%;
            transform: translateX(-50%) scaleY(1) scaleX(1.45);
            top: 180%;
            width: 160%;
            height: 190%;
            background-color: #009087;
            border-radius: 50%;
            display: block;
            transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
            z-index: -1;
          }

          &:hover {
            color: #ffffff;
            border: 1px solid #009087;
          }

          &:hover:before {
            top: -35%;
            background-color: #009087;
            transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
          }

          &:hover:after {
            top: -45%;
            background-color: #009087;
            transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
          }
        }
      }
      .headerCardDiv {
        .carNameCard {
          font-weight: 700;
          font-size: 32px;
          color: #c7c7c7;
          background-color: rgb(0, 0, 0);

          box-shadow: 5px 5px rgba(0, 0, 0, 0.791);
          padding: 10px;
          border-radius: 25px 0 25px 0;
        }
      }
    }
    &:hover {
      box-shadow: 0 0 20px rgba(39, 39, 39, 0.8);
      border-color: #282828;
    }
    .detailsDiv {
      display: flex;
      justify-content: space-around;
    }
    .imgCardDiv {
      .carImgCard {
        height: 200px;
        width: 300px;
      }
    }
  }
}
