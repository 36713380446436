.contact-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.contact-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.contact-container h1,
.contact-info h2 {
  text-align: center;
  margin: 20px;
}

.contact-container p {
  text-align: center;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 15px;
}

.btn {
  display: block;
  width: 100%;
}
.contact-info {
  text-align: center;
}
.contact-info,
.social-media {
  margin-top: 40px;
}
.map {
  max-width: 800px;
  margin: 0 auto;

  h2 {
    width: 100%;
    padding: 20px;
    margin: 20px 0 0 0;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  iframe {
    width: 100%;
    height: 400px;
    border: none;
  }
}
.social-media {
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  margin: 0 auto;
  max-width: 800px;

  a {
    display: inline-block;
    margin-right: 10px;
    text-decoration: none;
    color: #007bff;

    &a:hover {
      text-decoration: underline;
    }
  }
}
