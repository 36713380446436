.navContainer {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;

  .navbar {
    width: 50vw;

    .navLinkLogo {
      padding: 0.5rem;
      text-decoration: none;
      color: #000000;
      position: relative;
      transition: color 0.3s ease;
      font-weight: 700;
      &:hover {
        color: #000000;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 5px;
        background-color: rgb(33, 46, 106);
        transition: width 0.3s ease;
      }
      &:hover::before {
        width: 100%;
      }

      &.active {
        color: #000000;
        &::before {
          width: 100%;
        }
      }
    }
    .navLink {
      padding: 1rem;
      text-decoration: none;
      color: #000000;
      position: relative;
      transition: color 0.3s ease;
      font-weight: 700;
      &:hover {
        color: #000000;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 5px;
        background-color: rgb(33, 46, 106);
        transition: width 0.3s ease;
      }
      &:hover::before {
        width: 100%;
      }

      &.active {
        color: #000000;
        &::before {
          width: 100%;
        }
      }
    }
    .logout {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: #090909;
      padding: 0.5em 1em;
      font-size: 18px;
      border-radius: 0.5em;
      background: #e8e8e8;
      border: 1px solid #e8e8e8;
      // box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;

      &:active {
        color: #666;
        box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
      }

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scaleY(1) scaleX(1.25);
        top: 100%;
        width: 140%;
        height: 180%;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        display: block;
        transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
        z-index: -1;
      }

      &:hover {
        color: #ff0000;
        border: 1px solid #000000;
      }

      &:hover:before {
        top: -35%;
        background-color: #000000;
        transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
      }

      &:hover:after {
        top: -45%;
        background-color: #000000;
        transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
      }
    }
  }

  @include respond-to("sm") {
    //     width: 75%;
    .navbar {
      width: 75vw;
    }
  }
  @include respond-to("sm") {
    .navbar {
      width: 100vw;
    }
  }
}
